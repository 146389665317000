// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
// import { FaHome, FaSearch, FaClipboardList } from 'react-icons/fa'; // Import icons
// import './BottomNavbar.css'; // Ensure this file includes your styles

// function BottomNavbar() {
//   const location = useLocation();

//   // Check if the current path is for JobDetail
//   const isJobDetailPage = location.pathname.startsWith('/products/');
//   if (isJobDetailPage) return null; // Don't render navigation on JobDetail page

//   return (
//     <div className="bottom-navbar">
//       <Link to="/" className={`button-link ${location.pathname === '/' ? 'active' : ''}`}>
//         {/* <FaHome className="icon" /> */}
//         <span>Restaurants</span>
//       </Link>
//       <Link to="/search" className={`button-link ${location.pathname === '/search' ? 'active' : ''}`}>
//         {/* <FaSearch className="icon" /> */}
//         <span>Foods</span>
//       </Link>
//       <Link to="/tabbed" className={`button-link ${location.pathname === '/orders' ? 'active' : ''}`}>
//         {/* <FaClipboardList className="icon" /> */}
//         <span>Orders</span>
//       </Link>
//     </div>
//   );
// }

// export default BottomNavbar;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { FaHome, FaSearch, FaClipboardList } from 'react-icons/fa';
import './BottomNavbar.css'; // Link to the CSS styles

function BottomNavbar() {
  const location = useLocation();

  // Check if the current path is for JobDetail
  const isJobDetailPage = location.pathname.startsWith('/products/');
  if (isJobDetailPage) return null; // Don't render navigation on JobDetail page

  return (
    <div className="bottom-navbar">
      <Link to="/" className={`button-link ${location.pathname === '/' ? 'active' : ''}`}>
        <FaHome className="icon" />
        <span>Restaurants</span>
      </Link>
      <Link to="/search" className={`button-link ${location.pathname === '/search' ? 'active' : ''}`}>
        <FaSearch className="icon" />
        <span>Foods</span>
      </Link>
      <Link to="/tabbed" className={`button-link ${location.pathname === '/tabbed' ? 'active' : ''}`}>
        <FaClipboardList className="icon" />
        <span>Orders</span>
      </Link>
    </div>
  );
}

export default BottomNavbar;
