// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom'; // Import useNavigate for routing
// import '../style/ProfileForm.css';
// import { db } from '../firebaseConfig';
// import { doc, setDoc, getDoc, GeoPoint } from 'firebase/firestore';
// const tele = window.Telegram.WebApp;


// const ProfileForm = ({ onProfileSave }) => {
  

//   const [formData, setFormData] = useState({
//     fullName: '',
//     phone: '',
//     university: '',
//     dormNumber: '',
//     lat: '',
//     lon: '',
//     userId: '', // Initially empty, will be set from Telegram WebApp
//   });

  // useEffect(() => {
  //   tele.ready();
  // });
//   const [errorMessage, setErrorMessage] = useState('');
//   const [locationAccess, setLocationAccess] = useState(null);
//   const navigate = useNavigate(); // Hook for navigation

//   // Predefined university coordinates
  // const universityCoordinates = {
  //   'Mekelle University': { lat: 13.49, lon: 39.47 },
  //   'Hawassa University': { lat: 7.06, lon: 38.48 },
  //   'Gondar University': { lat: 12.6, lon: 37.46 },
  //   'Arba Minch University': { lat: 6.03, lon: 37.55 },
  //   'Adama Science and Technology University': { lat: 8.55, lon: 39.27 },
  //   'Debre Berhan University': { lat: 9.67, lon: 39.52 },
  //   'Jimma University': { lat: 7.67, lon: 36.83 },
  //   'Addis Ababa University': { lat: 9.03, lon: 38.74 },
  //   'Bahir Dar University': { lat: 11.60, lon: 37.39 },
  //   'Dilla University': { lat: 6.42, lon: 38.31 },
  //   'Dire Dawa University': { lat: 9.60, lon: 41.85 },
  //   'Debre Markos University': { lat: 10.37, lon: 37.73 },
  //   'Kombolcha University': { lat: 11.08, lon: 39.78 },
  //   'Woldia University': { lat: 11.52, lon: 39.60 },
  //   'Wollo University': { lat: 11.75, lon: 39.60 },
  //   'Haramaya University': { lat: 9.30, lon: 42.05 },
  //   'Gambela University': { lat: 8.25, lon: 34.58 },
  //   'Mettu University': { lat: 8.38, lon: 34.29 },
  //   'Shashemene University': { lat: 7.20, lon: 38.60 },
  //   'Bule Hora University': { lat: 7.08, lon: 39.61 },
  // };

  // useEffect(() => {
  //   // Function to get user ID
  //   const getUserId = () => {
  //     // Check if the Telegram WebApp is available
  //     const tele = window.Telegram?.WebApp;
  
  //     // If Telegram WebApp is present, call ready() to initialize
  //     tele?.ready();
  
  //     // Try to retrieve the user ID from Telegram WebApp (for official apps)
  //     const userIdFromTelegram = tele?.initDataUnsafe?.user?.id;
  
  //     // If we get the user ID from Telegram, update the state
  //     if (userIdFromTelegram) {
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         userId: userIdFromTelegram,
  //       }));
  //     } else {
  //       // If we can't retrieve user ID from Telegram WebApp, check the URL parameters
  //       const userIdFromUrl = new URLSearchParams(window.location.search).get('userId');
  
  //       if (userIdFromUrl) {
  //         // If we get the user ID from the URL, update the state
  //         setFormData((prevData) => ({
  //           ...prevData,
  //           userId: userIdFromUrl,
  //         }));
  //       } else {
  //         // If both methods fail, set an error message
  //         setErrorMessage('Unable to retrieve user ID from Telegram or URL.');
  //       }
  //     }
  //   };
  
  //   // Call the function to get the user ID
  //   getUserId();
  // }, []); // Empty dependency array to ensure it only runs on mount

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));

//     // Update latitude and longitude based on selected university
//     if (name === 'university') {
//       const coords = universityCoordinates[value] || { lat: '', lon: '' };
//       setFormData((prevData) => ({
//         ...prevData,
//         lat: coords.lat,
//         lon: coords.lon,
//       }));
//     }
//   };
 
//   const handleLocationRequest = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           setFormData((prevData) => ({
//             ...prevData,
//             lat: position.coords.latitude,
//             lon: position.coords.longitude,
//           }));
//           setLocationAccess(true);
//         },
//         () => {
//           setLocationAccess(false);
//           alert('Unable to retrieve your location. Please turn on location access.');
//         }
//       );
//     } else {
//       alert('Geolocation is not supported by your browser.');
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Validate phone number
//     const phonePattern = /^09\d{8}$/; // Regex for 10-digit phone starting with 09
//     if (!phonePattern.test(formData.phone)) {
//       setErrorMessage('Phone number must be 10 digits and start with "09".');
//       return;
//     }

//     // Validate required fields
//     if (!formData.fullName || !formData.university || !formData.dormNumber || !formData.lat || !formData.lon) {
//       setErrorMessage('Please fill in all required fields.');
//       return;
//     }

//     const timestamp = new Date().toISOString();
//     const location = new GeoPoint(parseFloat(formData.lat), parseFloat(formData.lon));

//     const userData = {
//       location: location,
//       timestamp: timestamp,
//       name: formData.fullName,
//       phone: formData.phone,
//       role: 'user',
//       university: formData.university,
//       dormNumber: formData.dormNumber,
//       lat:location.latitude,
//       lon:location.longitude,
//       userId: formData.userId, // Include userId here
//     };

//     try {
      // // Check if user ID exists in Firebase
      // const userDocRef = doc(db, "TelegramUsers", `${formData.userId}`);
      
      // // Save the profile data to Firestore
      // await setDoc(userDocRef, userData);

      // // Save profile data to local storage
      // localStorage.setItem('userProfile', JSON.stringify(userData));

      // onProfileSave(); // Notify parent component

  
      // navigate('/'); // Assuming "/" is your bottom navigation tab pages route
      // window.location.reload(); // This will refresh the page
//     } catch (e) {
      
//       setErrorMessage('An error occurred while saving your profile. Please try again.');
//     }
//   };

//   return (
//     <div className="form-container">
//       <form className="profile-form" onSubmit={handleSubmit}>
//         <h2> Yene Delivery Register</h2>

//         <label htmlFor="fullName">Full Name</label>
//         <input
//           type="text"
//           id="fullName"
//           name="fullName"
//           value={formData.fullName}
//           onChange={handleChange}
//           required
//           placeholder="Enter full name"
//         />

//         <label htmlFor="phone">Phone</label>
//         <input
//           type="number"
//           id="phone"
//           name="phone"
//           value={formData.phone}
//           onChange={handleChange}
//           required
//           placeholder="09XXXXXXXX"
//         />

//         <label htmlFor="university">University</label>
//         <select
//           id="university"
//           name="university"
//           value={formData.university}
//           onChange={handleChange}
//           required
//         >
//           <option value="">Select/city University</option>
//           {Object.keys(universityCoordinates).map((uni, index) => (
//             <option key={index} value={uni}>
//               {uni}
//             </option>
//           ))}
//         </select>

//         <label htmlFor="dormNumber">Dorm Number</label>
//         <input
//           type="text"
//           id="dormNumber"
//           name="dormNumber"
//           value={formData.dormNumber}
//           onChange={handleChange}
//           required
//           placeholder="Enter Dorm Block or Home number"
//         />

//         <button type="button" onClick={handleLocationRequest}>
//           Turn on Location
//         </button>

//         {locationAccess === false && (
//           <p className="error-message">
//             Unable to access your location. Using coordinates from the selected university.
//           </p>
//         )}

//         <p>
//           Latitude: {formData.lat || 'N/A'}, Longitude: {formData.lon || 'N/A'}
//         </p>

//         {errorMessage && <p className="error-message">{errorMessage}</p>}

//         {formData.lat && formData.lon && (
//           <button type="submit">Submit</button>
//         )}
//       </form>
//       <div className="footer-message">
//         <p>All rights reserved &copy; Yene Food Delivery.</p>
//         <p>We provide quick and reliable food delivery services to your doorstep. Enjoy your meals with just a click!</p>
//       </div>
//     </div>
//   );
// };

// export default ProfileForm;


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/ProfileForm.css';
import { db } from '../firebaseConfig';
import { doc, setDoc, GeoPoint } from 'firebase/firestore';
const tele = window.Telegram.WebApp;

const ProfileForm = ({ onProfileSave }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    phone: '',
    university: '',
    city: '',
    subCity: '',
    dormNumber: '',
    lat: '',
    lon: '',
    role: '',
    userId: '',
  });
  useEffect(() => {
    tele.ready();
  });

  const [isStudent, setIsStudent] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');
  const [locationAccess, setLocationAccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

 
  const universityCoordinates = {
    'Mekelle University': { lat: 13.4798331, lon: 39.484426 },
    'Hawassa University': { lat: 7.06, lon: 38.48 },
    'Gondar University': { lat: 12.6, lon: 37.46 },
    'Arba Minch University': { lat: 6.03, lon: 37.55 },
    'Adama Science and Technology University': { lat: 8.5644912, lon: 39.2905463 },
    'Addis Ababa Science and Technology University': { lat: 8.8848649, lon: 38.8081957},
    'Debre Berhan University': { lat: 9.67, lon: 39.52 },
    'Jimma University': { lat: 7.67, lon: 36.83 },
    'Addis Ababa University': { lat: 9.03, lon: 38.74 },
    'Bahir Dar University': { lat: 11.60, lon: 37.39 },
    'Dilla University': { lat: 6.42, lon: 38.31 },
    'Dire Dawa University': { lat: 9.60, lon: 41.85 },
    'Debre Markos University': { lat: 10.37, lon: 37.73 },
    'Kombolcha University': { lat: 11.08, lon: 39.78 },
    'Woldia University': { lat: 11.52, lon: 39.60 },
    'Wollo University': { lat: 11.75, lon: 39.60 },
    'Haramaya University': { lat: 9.30, lon: 42.05 },
    'Gambela University': { lat: 8.25, lon: 34.58 },
    'Mettu University': { lat: 8.38, lon: 34.29 },
    'Shashemene University': { lat: 7.20, lon: 38.60 },
    'Bule Hora University': { lat: 7.08, lon: 39.61 },
  };


const cityCoordinates = {
  'Addis Ababa': { lat: 9.03, lon: 38.74 },
  'Hawassa': { lat: 7.06, lon: 38.48 },
  'Gondar': { lat: 12.60, lon: 37.47 },
  'Dire Dawa': { lat: 9.60, lon: 41.86 },
  'Mekelle': { lat: 13.49, lon: 39.47 },
  'Jimma': { lat: 7.67, lon: 36.83 },
  'Bahir Dar': { lat: 11.60, lon: 37.38 },
  'Adama': { lat: 8.54, lon: 39.27 },
  'Harar': { lat: 9.31, lon: 42.12 },
  'Shashamane': { lat: 7.20, lon: 38.59 },
  'Debre Markos': { lat: 10.34, lon: 37.72 },
  'Dessie': { lat: 11.13, lon: 39.63 },
  'Sodo': { lat: 6.85, lon: 37.75 },
  'Arba Minch': { lat: 6.04, lon: 37.55 },  // Newly added
  'Jinka': { lat: 5.65, lon: 36.65 },       // Newly added
  'Wolaita Sodo': { lat: 6.84, lon: 37.75 },// Newly added
  'Axum': { lat: 14.12, lon: 38.72 },       // Newly added
  'Asosa': { lat: 10.07, lon: 34.53 },      // Newly added
  'Debre Birhan': { lat: 9.68, lon: 39.53 },// Newly added
  'Nekemte': { lat: 9.08, lon: 36.53 },     // Newly added
  // Add other cities as needed
};

const subCities = {
  'Bole': { lat: 9.0183, lon: 38.8029 },
  'Yeka': { lat: 9.0229, lon: 38.8004 },
  'Lideta': { lat: 9.0186, lon: 38.7270 },
  'Nifas Silk': { lat: 8.9363235, lon: 38.7338714},
  'Kolfe Keranio': { lat: 9.00, lon: 38.67 },
  'Gulele': { lat: 9.08, lon: 38.71 },
  'Arada': { lat: 9.04, lon: 38.74 },
  'Akaki Kality': { lat: 8.91, lon: 38.74 },
  'Addis Ketema': { lat: 9.0477384, lon: 38.7212505 },
  'Nifas Silk Lafto': { lat: 8.9363235, lon: 38.7338714 },  // Newly added full name
  'Ledeta': { lat: 9.00, lon: 38.72 },            // Repeated to fix a typo
  'Kirkos': { lat: 9.02, lon: 38.76 },            // Added duplicate to fix a slight mismatch
  // Add more sub-cities if needed
};

  useEffect(() => {
    window.Telegram.WebApp.ready();
    // Function to get user ID
    const getUserId = () => {
      // Check if the Telegram WebApp is available
      const tele = window.Telegram?.WebApp;
  
      // If Telegram WebApp is present, call ready() to initialize
      tele?.ready();
  
      // Try to retrieve the user ID from Telegram WebApp (for official apps)
      const userIdFromTelegram = tele?.initDataUnsafe?.user?.id;
  
      // If we get the user ID from Telegram, update the state
      if (userIdFromTelegram) {
        setFormData((prevData) => ({
          ...prevData,
          userId: userIdFromTelegram,
        }));
      } else {
        // If we can't retrieve user ID from Telegram WebApp, check the URL parameters
        const userIdFromUrl = new URLSearchParams(window.location.search).get('userId');
  
        if (userIdFromUrl) {
          // If we get the user ID from the URL, update the state
          setFormData((prevData) => ({
            ...prevData,
            userId: userIdFromUrl,
          }));
        } else {
          // setFormData((prevData) => ({
          //   ...prevData,
          //   userId: 761513957, // this make deletable
          // }));
          // If both methods fail, set an error message
          setErrorMessage('Unable to retrieve user ID from Telegram or URL.');
        }
      }
    };
  
    // Call the function to get the user ID
    getUserId();
  }, []); // Empty dependency array to ensure it only runs on mount


  const handleRoleChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      role: value,
    }));
    setIsStudent(value === 'student');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'city') {
      setSelectedCity(value);
      const coords = cityCoordinates[value] || { lat: '', lon: '' };
      setFormData((prevData) => ({
        ...prevData,
        lat: coords.lat,
        lon: coords.lon,
      }));
    }
        // Check for sub-city if the city is selected
    if (name === 'subCity') {
      const coords = subCities[value] || { lat: '', lon: '' }; // Use subCities for sub-city coordinates
      setFormData((prevData) => ({
        ...prevData,
        lat: coords.lat,
        lon: coords.lon,
      }));
    }

    if (name === 'university' && isStudent) {
      const coords = universityCoordinates[value] || { lat: '', lon: '' };
      setFormData((prevData) => ({
        ...prevData,
        lat: coords.lat,
        lon: coords.lon,
      }));
    }
  };

  const handleLocationRequest = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormData((prevData) => ({
            ...prevData,
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          }));
          setLocationAccess(true);
        },
        () => {
          setLocationAccess(false);
          alert('Unable to retrieve your location. Please turn on location access.');
        }
      );
    } else {
      alert('Geolocation is not supported by your browser.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const phonePattern = /^09\d{8}$/;
    if (!phonePattern.test(formData.phone)) {
      setErrorMessage('Phone number must be 10 digits and start with "09".');
      return;
    }

    if (!formData.fullName || (!formData.university && !formData.city)) {
      setErrorMessage('Please fill in all required fields.');
      return;
    }

    const timestamp = new Date().toISOString();
    const Location = new GeoPoint(parseFloat(formData.lat), parseFloat(formData.lon));

    console.log("Latitude:", formData.lat, "Longitude:", formData.lon);

    const userData = {
      Location,
      timestamp,
      name: formData.fullName,
      phone: formData.phone,
      role: formData.role,
      university: isStudent ? formData.university : `${formData.city} / ${formData.subCity}`, // Use city and subCity if not a student
      // Remove city and subCity if the user is a student
      dormNumber: formData.dormNumber,
      userId: formData.userId,
      lat:Location.latitude,
      lon:Location.longitude,

    };
     // Conditionally add city and subCity if the user is not a student
     if (!isStudent) {
      userData.city = formData.city;
      userData.subCity = formData.subCity;
  }

    try {
     // Check if user ID exists in Firebase
     const userDocRef = doc(db, "TelegramUsers", `${formData.userId}`);
      
     // Save the profile data to Firestore
     await setDoc(userDocRef, userData);

     // Save profile data to local storage
     localStorage.setItem('userProfile', JSON.stringify(userData));

     onProfileSave(); // Notify parent component

 
     navigate('/'); // Assuming "/" is your bottom navigation tab pages route
     window.location.reload(); // This will refresh the page
    // console.log("user data",userData)
    } catch (e) {
      console.log(e);
      setErrorMessage('An error occurred while saving your profile. Please try again.');
    }
  };

  return (
    <div className="form-container">
      <form className="profile-form" onSubmit={handleSubmit}>
        <h2>Yene Delivery Register</h2>

        <label htmlFor="fullName">Full Name</label>
        <input
          type="text"
          id="fullName"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          required
        />

        <label htmlFor="phone">Phone</label>
        <input
          type="number"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
          placeholder="09XXXXXXXX"
        />

      <label htmlFor="role">Are you a university student?</label>
        <select id="role" name="role" value={formData.role} onChange={handleRoleChange}>
          <option value="">Select role</option>
          <option value="student">University Student</option>
          <option value="non-student">City Resident</option>

        </select>

        {isStudent ? (
          <>
            <label htmlFor="university">University</label>
            <select
              id="university"
              name="university"
              value={formData.university}
              onChange={handleChange}
              required
            >
              <option value="">Select university</option>
              {Object.keys(universityCoordinates).map((uni) => (
                <option key={uni} value={uni}>
                  {uni}
                </option>
              ))}
            </select>
          </>
        ) : (
          <>
            <label htmlFor="city">City</label>
            <select id="city" name="city" value={formData.city} onChange={handleChange} required>
              <option value="">Select city</option>
              {Object.keys(cityCoordinates).map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </select>

            {selectedCity === 'Addis Ababa' && (
              <>
                <label htmlFor="subCity">Sub-City</label>
                <select
                  id="subCity"
                  name="subCity"
                  value={formData.subCity}
                  onChange={handleChange}
                >
                  <option value="">Select sub-city</option>
                  {Object.keys(subCities).map((subCity) => (
                    <option key={subCity} value={subCity}>
                      {subCity}
                    </option>
                  ))}
                </select>
              </>
            )}
          </>
        )}

      <label htmlFor="dormNumber">Dormitory/Block/Building Name with Number</label>

        <input
          type="text"
          id="dormNumber"
          name="dormNumber"
          value={formData.dormNumber}
          onChange={handleChange}
          required
          placeholder="Enter Dorm Block or Home number"
        />

        <button type="button" onClick={handleLocationRequest}>
          Turn on Location
        </button>

        {locationAccess === false && <p className="error-message">Unable to access your location.</p>}

        {formData.lat && formData.lon && <p>Latitude: {formData.lat}, Longitude: {formData.lon}</p>}

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <button type="submit">Submit</button>
      </form>
      <div className="footer">
        <p>By clicking "Submit", you agree to our terms and conditions.</p>
      </div>
    </div>
  );
};

export default ProfileForm;
